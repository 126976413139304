<script setup lang="ts">
defineProps({
    colors: {
        type: Array as PropType<string[]>,
        default: () => ["#93A4BC", "#5A6273"]
    }
});

const randomId = computed(() => Math.random().toString(36).substr(2, 9));
</script>

<template>
    <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M28.4152 0H23.6287C23.3057 0 23.0439 0.242172 23.0439 0.540865V3.24519H19.1404V0.540865C19.1404 0.242172 18.8785 0 18.5556 0H13.4415C13.1186 0 12.8567 0.242172 12.8567 0.540865V3.24519H8.95614V0.540865C8.95614 0.242172 8.6943 0 8.37135 0H3.5848C3.26184 0 3 0.242172 3 0.540865V6.34706C3.00322 7.81098 4.2856 8.99703 5.86842 9H26.1316C27.7144 8.99703 28.9968 7.81098 29 6.34706V0.540865C29 0.242172 28.7382 0 28.4152 0Z"
            :fill="'url(#paint0_linear_732_69920' + randomId + ')'"
        />
        <path
            d="M6.7898 11H25.2074L25.9999 28.4959V28.6649C26.0053 29.4965 25.7176 30.3046 25.1851 30.9537C24.6963 31.5676 23.9621 31.9483 23.1675 32H20V24C20 21.7909 18.2091 20 16 20C13.7909 20 12 21.7909 12 24V32H8.83248C8.03787 31.9483 7.30375 31.5676 6.81491 30.9537C6.28241 30.3046 5.9947 29.4965 6.00007 28.6649V28.4959L6.7898 11Z"
            :fill="'url(#paint1_linear_732_69920' + randomId + ')'"
        />
        <defs>
            <linearGradient
                :id="'paint0_linear_732_69920' + randomId"
                x1="16"
                y1="0"
                x2="16"
                y2="32"
                gradientUnits="userSpaceOnUse"
            >
                <stop :stop-color="colors[0]" />
                <stop offset="1" :stop-color="colors[1]" />
            </linearGradient>
            <linearGradient
                :id="'paint1_linear_732_69920' + randomId"
                x1="16"
                y1="0"
                x2="16"
                y2="32"
                gradientUnits="userSpaceOnUse"
            >
                <stop :stop-color="colors[0]" />
                <stop offset="1" :stop-color="colors[1]" />
            </linearGradient>
        </defs>
    </svg>
</template>
